<template>
  <div class="container">
    <Title :title="$t('3f8bf39')" :des="$t('ae48d27')" />
    <ReleaseDesk />
  </div>
</template>

<script>
import ReleaseDesk from '@/components/ReleaseDesk'
import Title from '@/components/Title'
export default {
  name: 'ReleaseService',
  components: {
    ReleaseDesk,
    Title,
  },
  props: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.container {
  width: 100%;
  background: #fff;
}
</style>
